import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Reveal from "../motion/Reveal";
import { FaAngleLeft } from "react-icons/fa6";

const Slider = () => {
  return (
    <div class=" bg-[#0A0A0A] py-16 px-4 lg:h-[90vh] relative items-center flex flex-col-reverse lg:grid lg:grid-cols-3 lg:px-12 xl:px-20 2xl:px-32 gap-6">
      <img
        src="/Rectangle (2).png"
        className="absolute left-0 top-0 w-full h-full object-cover opacity-75"
        alt="bg pattern"
      />
      <span className="text-[#fafafa] lg:col-span-2 text-center z-10">
        <Reveal>
          <h2 className="text-[32px] lg:text-[75px]">به قدرت خود پی ببرید</h2>
        </Reveal>
        <Reveal>
          <span className=" gap-1 flex-wrap text-[24px] lg:text-[42px]">
            <h1 className="text-[#81F6B4] inline text-[30px] lg:text-[42px]">'من قوی'</h1>{" "}
            بستری برای ارتقاء توانمندی ها و پیشرفت شخصی و حرفه ای
          </span>
        </Reveal>
      </span>

      <span className="flex gap-7 flex-col z-10">
        <Reveal head>
          <video
            autoPlay
            playsInline
            loop
            className="w-full border border-[#fff]/60 rounded-[16px] object-cover"
            src="/video/landing.mp4"
            alt="Slider Video"
            muted
            preload="auto"
          />
        </Reveal>
        <Reveal head>
          <Link
            to={"/sign"}
            className=" text-[20px] p-3 cursor-pointer bg-[--brand] text-white font-[Shabnam-thin] flex justify-center items-center rounded-[25px] gap-1"
          >
            پیشرفت رو با ما شروع کن
            <FaAngleLeft size={22} />
          </Link>
        </Reveal>
      </span>
    </div>
  );
};

export default Slider;
