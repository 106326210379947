import React from "react";
import Reveal from "../motion/Reveal";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import HelpAnimation from "../../lottie/help.json";
import { SrviceInfo } from "../../pages/ServiceDetails";
import { FaRegUser, FaUser, FaUserAlt } from "react-icons/fa";
import KuchingAnimation from "../../lottie/test (1).json";

const StartNow = () => {
  return (
    <div class="u-s-p-y-60">
      {/* <div class="section__intro u-s-m-b-40">
        <div class="container">
          <div style={{ justifyContent: "center" }} class="row">
            <Reveal>
              <div class="section__text-wrap">
                <h1 class="section__heading u-c-secondary u-s-m-b-12">
                  چطوری میتونیم کمکت کنیم؟
                </h1>
              </div>
            </Reveal>
          </div>
        </div>
      </div> */}
      <div class="section__content px-3 lg:px-0">
        <div class="container">
          <div className="row flex-col lg:flex-row lg:justify-between items-center">
           
            <div class="col-lg-6 p-0">
              <Reveal left duration={0.9}>
                <div class="slider-content slider-content--animation gap-4  w-fit mx-auto py-4  flex flex-col">
                  <span class="text-[20px] lg:text-[48px]  text-[#1B1F37]">
                    نمیدونی از کجا شروع کنی؟
                  </span>

                  <span class="text-[#1B1F37] text-[16px] lg:text-[24px]">
                    شمارت رو ثبت کن تا مشاوران ما باهات تماس بگیرن
                  </span>

                  <div className="flex relative">
                    <input
                      className="w-full h-[52px] px-12 placeholder:font-thin font-thin rounded-full outline-none bg-[#F5F5F5] placeholder:text-[#A5A5A5]"
                      placeholder="شماره موبایل"
                    />
                    <FaRegUser className="absolute right-6 top-1/2 -translate-y-1/2" />
                    <button className=" btn--e-primary absolute left-[3px] top-1/2 -translate-y-1/2 h-[46px] px-5">
                      ثبت
                    </button>
                  </div>
                </div>
              </Reveal>
            </div>
            <div class="col-lg-5 p-0">
              <Reveal duration={0.9}>
                {/* <img
                  src="/Active Support-rafiki1.png"
                  className="w-full"
                  alt="sirir.ir"
                /> */}
                <Lottie animationData={KuchingAnimation} loop autoPlay/>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartNow;
