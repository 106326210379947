import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { Link } from "react-router-dom";
import Reveal from "../components/motion/Reveal";
import Reviews from "../components/home/Reviews";
import { FaAngleLeft } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";

const About = () => {
  return (
    <div>
      <Header />
      <div class="app-content bg-[#fafafa]">
        <div class="u-s-p-y-60">
          <div class="section__content ">
            <div class="container">
              <div class="grid lg:grid-cols-2  items-center  overflow-hidden">
                <div class="text-center text-[#292929] lg:py-3 px-3 lg:px-7 order-[2] lg:order-1">
                  <h2 class="text-[#03C976] text-[32px] lg:text-[48px]">
                    من قوی
                  </h2>
                  <div class=" py-4">
                    <p class="text-[14px] lg:text-[16px]">
                      "من قوی"، جایی برای آغاز توانمندی نسل آینده! از اینکه شما
                      یک قهرمان هستید شروع کنید. در "من قوی"، هدف ما تقویت نسل
                      های آینده است. در کنار ما نه تنها توانمندی خود را کشف
                      می‌کنید بلکه به رهبری در زندگی شخصی و حرفه‌ای خود
                      می‌پردازید. به جمع ما بپیوندید و با هم به سوی قله‌های جدید
                      پیش برویم.
                    </p>
                  </div>
                  <div className=" my-3">
                    <span className="border rounded-full p-3 border-[#29292998]">
                      "من قوی"، جایی برای رشد و توسعه شماست!
                    </span>
                  </div>
                  <div className="flex justify-center mt-6 ">
                    <Reveal width={"fit-content"}>
                      <Link
                        to={"/contact"}
                        className="btn--e-brand text-[14px] px-4 py-2 flex items-center gap-2"
                      >
                        ثبت نام
                      </Link>
                    </Reveal>
                  </div>
                </div>
                <div className="bg-[#F0F8FF] order-[1] lg:order-2">
                  <Reveal head>
                    <img
                      src="/meeting-not-css.svg"
                      className="w-full"
                      alt="About Svg"
                    />
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="u-s-p-b-10">
          <div class="section__intro u-s-m-b-46">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="section__text-wrap">
                    <Reveal head>
                      <h1 class="text-[32px] lg:text-[40px] text-[#292929]">تیم سیریر</h1>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section__content mb-16">
            <div class="container">
              <Swiper
                breakpoints={{
                  10: { slidesPerView: 2 },
                  620: { slidesPerView: 3 },
                  1024: { slidesPerView: 4 },
                  1254: { slidesPerView: 5 },
                }}
                spaceBetween={30}
              >
                {Array(5)
                  .fill("")
                  .map(() => (
                    <SwiperSlide>
                      <div class="w-full">
                        <Reveal>
                          <div class="team-member u-h-100">
                            <div class="team-member__wrap">
                              <div class="aspect aspect--bg-grey-fb aspect--square">
                                <img
                                  class="aspect__img rounded-full team-member__img"
                                  src="images/about/member-1.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="team-member__social-wrap">
                                <ul class="team-member__social-list">
                                  <li>
                                    <a class="s-tw--bgcolor-hover" href="#">
                                      <i class="fa-brands fa-twitter"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a class="s-fb--bgcolor-hover" href="#">
                                      <i class="fa-brands fa-facebook-f"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a class="s-insta--bgcolor-hover" href="#">
                                      <i class="fa-brands fa-instagram"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a class="s-linked--bgcolor-hover" href="#">
                                      <i class="fa-brands fa-linkedin"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="text-center mt-3">
                              <h5 class="text-black text-[18px]">
                              مهیار بابازاده
                              </h5>
                              <span class="text-black text-[18px]">
                                مدیریت
                              </span>
                            </div>
                          </div>
                        </Reveal>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
        <Reviews />
      </div>
      <Footer />
    </div>
  );
};

export default About;
