import React from "react";
import Reveal from "../motion/Reveal";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import HelpAnimation from "../../lottie/help.json";
import { SrviceInfo } from "../../pages/ServiceDetails";

const HelpYou = () => {
  return (
    <div class="u-s-p-y-60">
      {/* <div class="section__intro u-s-m-b-40">
        <div class="container">
          <div style={{ justifyContent: "center" }} class="row">
            <Reveal>
              <div class="section__text-wrap">
                <h1 class="section__heading u-c-secondary u-s-m-b-12">
                  چطوری میتونیم کمکت کنیم؟
                </h1>
              </div>
            </Reveal>
          </div>
        </div>
      </div> */}

      <div class="section__content">
        <div class="container">
          <div className="row flex-col-reverse lg:flex-row lg:justify-between items-center">
            <div class="flex flex-col gap-4">
              {SrviceInfo.map((e, i) => {
                return (
                  <div class="col-12  mb-6 lg:mb-7">
                    <Reveal delay={(i + 1) * 0.15 + 0.25}>
                      <Link to={"/service/" + e.id} class="">
                        <div className="grid lg:grid-cols-5 items-center w-full gap-2 lg:gap-20">
                          <img
                            className={`w-full rounded-[30px] h-[230px] object-cover lg:col-span-2  ${
                              i % 2 ? "lg:order-1" : "lg:order-2"
                            }`}
                            alt="Test"
                            src={e.img}
                          />
                          <span
                            style={{
                              background:
                                i % 2
                                  ? "linear-gradient(125.76deg, #03C976 0.97%, #2A3056 100%)"
                                  : "linear-gradient(125.76deg, #67FFF2 0.97%, #2A3056 100%)",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                            }}
                            className={`lg:col-span-3 py-2 lg:py-5  ${
                              i % 2
                                ? "lg:order-2 lg:ps-10"
                                : "lg:order-1 lg:pe-10"
                            }`}
                          >
                            <h3 className="text-[28px] lg:text-[48px] mb-3 lg:mb-10 text-left font-bold font-sans">
                              {e.title_en}
                            </h3>
                            <h3 className="text-[24px] lg:text-[40px] mb-1   lg:mb-6">
                              {e.title}
                            </h3>
                            <p className="text-[18px] lg:text-[24px]">
                              {e.subTitle}
                            </p>
                          </span>
                        </div>
                      </Link>
                    </Reveal>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpYou;
