import React, { useContext, useEffect, useRef, useState } from "react";
import Reveal from "../motion/Reveal";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";
import RateToStar from "../../utils/rateToStart";

const ProductCard = ({ data, home, full }) => {
  const { name, category, price, sepcial_price, img, _id, rate, reviews } =
    data;
  const myRef = useRef(null);

  return (
    <div
      style={{ padding: "8px" }}
      className={`${
        full
          ? "col-12"
          : home
          ? "col-xl-3 col-lg-4 col-md-6 col-10"
          : "col-xl-4 col-lg-4 col-md-6 col-6"
      }  filter__item headphone`}
    >
      <Reveal head>
        <div className="product-o product-o--hover-on product-o--radius">
          <Link to={"/products/detail/" + _id}>
            <div className="product-o__wrap">
              <a
                className="aspect aspect--bg-grey aspect--square u-d-block"
                href="product-detail.html"
              >
                <img
                  className="aspect__img object-cover rounded-[16px]"
                  // src="/images/product/electronic/product3.jpg"
                  src={CDN_BASE_URL + img}
                  alt=""
                />
              </a>
              <div className="product-o__action-wrap">
                <ul className="product-o__action-list">
                  <li>
                    <a
                      data-modal="modal"
                      data-modal-id="#quick-look"
                      data-tooltip="tooltip"
                      data-placement="top"
                      title="Quick View"
                    >
                      <i className="fa-solid fa-search-plus"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <span className="product-o__category flex justify-between pt-2">
              <span>{category.name}</span>
              <span className="product-o__review">
                {" "}
                <RateToStar number={rate} />
              </span>
            </span>

            <div className="h-[40px]">
              <h4 className="text-[14px] leading-4 mt-2 text-[#1A1A1A]">{name}</h4>
            </div>
          </Link>
          <div class="table-p__input-counter-wrap mt-3 w-full">
            <Link
              to={"/products/detail/" + _id}
              style={{ fontSize: "14px" }}
              className="btn p-2 bg-[--brand] text-white  flex justify-center items-center rounded-[25px]"
            >
              مشاهده محصول
            </Link>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default ProductCard;
