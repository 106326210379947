import React from "react";
import Reveal from "../motion/Reveal";
import { Link } from "react-router-dom";
import { SrviceInfo } from "../../pages/ServiceDetails";
import {
  FaEnvelope,
  FaInstagram,
  FaRegEnvelope,
  FaTelegramPlane,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div class="outer-footer relative">
        <img
          src="/Rectangle (2).png"
          className="absolute left-0 z top-0 w-full h-full object-cover opacity-35"
          alt="bg pattern"
        />
        <div class="px-4 lg:px-8 2xl:px-24">
          <div class="row gap-8 lg:gap-0">
            <div class="col-lg-8 col-md-12 text-[#FAFAFA] text-[14px]">
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-2">
                <span>
                  <h2 className="text-[16px] lg:text-[18px]">خدمات مشتریان</h2>
                  <ul className="list-disc ps-5 mt-3 leading-8 text-[12px] font-[Shabnam-thin] lg:text-[16px]">
                    {SrviceInfo?.map((item) => (
                      <li>
                        <Link to={"/service/" + item.id}> {item.title}</Link>
                      </li>
                    ))}
                  </ul>
                </span>
                <span>
                  <h2 className="text-[16px] lg:text-[18px]">محصولات آموزشی</h2>
                  <ul className="list-disc ps-5 mt-3 leading-8 text-[12px] font-[Shabnam-thin] lg:text-[16px]">
                    <li>
                      <Link to="/products/courses">دوره های آموزشی</Link>
                    </li>
                    <li>
                      <Link to="/products/pdf-courses">
                        دوره های آموزشی PDF
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/audio">فایل های صوتی</Link>
                    </li>
                  </ul>
                </span>
                <span>
                  <h2 className="text-[16px] lg:text-[18px]">روادید</h2>
                  <ul className="list-disc ps-5 mt-3 leading-8 text-[12px] font-[Shabnam-thin] lg:text-[16px]">
                    <li>
                      <Link to="/event">سمینار</Link>
                    </li>
                    <li>
                      <Link to="/event">تور</Link>
                    </li>
                    <li>
                      <Link to="/event">کارگاه آموزشی</Link>
                    </li>
                    <li>
                      <Link to="/event">بوت کمپ</Link>
                    </li>
                  </ul>
                </span>
                <span>
                  <h2 className="text-[16px] lg:text-[18px]">تماس با ما</h2>
                  <ul className="list-disc ps-5 mt-3 leading-8 text-[12px] font-[Shabnam-thin] lg:text-[16px]">
                    <li>
                      <Link to="tel:099999999">شماره تماس : 099999999</Link>
                    </li>
                    <li>
                      <Link to="mail:siri.ir@gmail.com">
                        آدرس ایمیل : siri.ir@gmail.com
                      </Link>
                    </li>
                  </ul>
                </span>
                <span className="col-span-full lg:mt-8">
                  <h2 className="text-[16px] lg:text-[18px] mb-4">
                    درباره سیریر
                  </h2>
                  <p className="lg:text-[16px] text-[11px]">
                    به سیریر خوش آمدید! ما یک تیم حرفه‌ای و متعهد هستیم که
                    هدف‌مان ارائه خدمات مشاوره‌ای و تخصصی در حوزه‌های مختلف به
                    شماست. با سال‌ها تجربه در زمینه‌های طراحی سایت، سئو،
                    تبلیغات، کوچینگ و تولید محتوا، ما به کسب و کارها کمک می‌کنیم
                    تا در دنیای دیجیتال پیشرفت کنند.
                  </p>
                </span>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 text-[#FAFAFA] font-[Shabnam-thin] flex flex-col justify-between gap-6 lg:gap-0">
              <span class="">
                <h4 className="text-[24px]">
                  زودتر از همه از تخفیفات باخبر شو!
                </h4>
                <div className="flex relative font-[Shabnam-thin] w-full mt-5">
                  <input
                    className="w-full h-[52px] px-12 placeholder:font-thin font-thin rounded-full outline-none bg-[#F5F5F5] placeholder:text-[#A5A5A5] text-[#434343]"
                    placeholder="ایمیل"
                    type="email"
                    id="email"
                    name="email"
                  />
                  <FaRegEnvelope
                    color="#434343"
                    className="absolute right-6 top-1/2 -translate-y-1/2"
                  />
                  <button className=" btn--e-brand absolute left-[3px] top-1/2 -translate-y-1/2 h-[46px] px-6">
                    ثبت
                  </button>
                </div>
              </span>
              <span class="">
                <h4 className="text-[20px]">
                  سیری را در شبکه های اجتماعی دنبال کنید
                </h4>
                <div className="flex justify-between font-[Shabnam-thin] text-[26px] w-full mt-2 border-b py-4">
                  <Link>
                    <FaInstagram />
                  </Link>
                  <Link>
                    <FaYoutube />
                  </Link>
                  <Link>
                    <FaWhatsapp />
                  </Link>
                  <Link>
                    <FaTelegramPlane />
                  </Link>
                </div>
              </span>
              <span class="">
                <div className="flex justify-around font-[Shabnam-thin] text-[26px] w-full">
                  <Link>
                    <img
                      src="/b7579a123e3c54bc614ed2b83758f086.jpg"
                      className="w-[68px] h-[80px] object-cover rounded-sm"
                      alt="Sirir"
                    />
                  </Link>
                  <Link>
                    <img
                      src="/b7579a123e3c54bc614ed2b83758f086.jpg"
                      className="w-[68px] h-[80px] object-cover rounded-sm"
                      alt="Sirir"
                    />
                  </Link>
                  <Link>
                    <img
                      src="/b7579a123e3c54bc614ed2b83758f086.jpg"
                      className="w-[68px] h-[80px] object-cover rounded-sm"
                      alt="Sirir"
                    />
                  </Link>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="lower-footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="lower-footer__content">
                <div class="lower-footer__copyright">
                  <span>© تمامی حقوق محفوظ است</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
