import React, { useEffect, useRef, useState } from "react";
import Reveal from "../motion/Reveal";
import { httpReauest } from "../../utils/httprequest";
import Loading from "../common/Loading";
import Countdown from "react-countdown";
import CustomModal from "../common/Modal";
import SignupForm from "../common/SignupForm";
import { FaLock, FaRegUser, FaTimes } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import TopProducts from "./TopProducts";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "../common/ProductCard";
import { Autoplay } from "swiper/modules";
import { CDN_BASE_URL } from "../../config";
import { toast } from "react-toastify";

const VITE_SITE_KEY = "6LchGzQqAAAAAACh11TFb0yF1BUb3nQAZMNZUPJD";
const SITE_SECRET = "6LchGzQqAAAAAM1RpZ-oQwac9Bas5x6L19KvPyHL";

const Dontmiss = () => {
  const [hour, sethours] = useState();
  const [show, setshow] = useState();
  const [data, setdata] = useState();
  const [products, setproducts] = useState();
  const [loading, setLoading] = useState();
  const [phone, setphone] = useState("");
  const [step, setstep] = useState();
  const [check, setcheck] = useState();
  const [code, setcode] = useState();
  const [completed, setcompleted] = useState(false);
  const recaptcha = useRef(null);

  async function getData(params) {
    setLoading(true);
    await httpReauest("GET", "/admin/gift", {}, {}).then(({ data }) => {
      sethours(data?.data?.date);
      setdata(data?.data);
    });
    const newData = await httpReauest("GET", "/product", {}, {});

    setproducts(newData.data.data);
    setLoading(false);
  }

  async function postUser() {
    if (phone?.length !== 11) {
      return toast.error("فرمت شماره تماس صحیح نیست");
    }
    await httpReauest("POST", "/admin/gift/user", { phone }, {}).then(
      ({ data }) => {
        setstep(true);
      }
    );
  }

  async function acceptUser(params) {
    await httpReauest(
      "POST",
      "/admin/gift/user/accept",
      { phone, code },
      {}
    ).then(() => {
      window.location.assign(CDN_BASE_URL + data?.file);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   console.log(recaptcha?.current);
  //
  // }, [recaptcha.current]);

  const renderer = ({ hours, minutes, seconds, completed, days }) => {
    if (completed) {
      setcompleted(true);
      return (
        <span className="px-3  flex flex-col justify-center items-center bg-[#2A3056]  p-2 rounded mt-4">
          متاسفانه زمان دریافت هدیه تمام شد
        </span>
      );
    } else {
      return (
        <div className=" p-3 flex   text-[#fafafa] gap-3 ">
          <span className="px-3  flex flex-col justify-center items-center bg-[#2A3056] lg:w-[68px] lg:h-[68px] w-[52px] h-[52px] p-2 rounded-[16px]">
            <h6 className="m-0 lg:text-[20px] text-[14px]">
              {seconds < 10 ? "0" + seconds : seconds}
            </h6>
            <h6 className="text-[12px] lg:text-[16px]">ثانیه</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center bg-[#2A3056] lg:w-[68px] lg:h-[68px] w-[52px] h-[52px] p-2 rounded-[16px]">
            <h6 className="m-0 lg:text-[20px] text-[14px]">
              {minutes < 10 ? "0" + minutes : minutes}
            </h6>
            <h6 className="text-[12px] lg:text-[16px]">دقیقه</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center bg-[#2A3056] lg:w-[68px] lg:h-[68px] w-[52px] h-[52px] p-2 rounded-[16px]">
            <h6 className="m-0 lg:text-[20px] text-[14px]">
              {hours < 10 ? "0" + hours : hours}
            </h6>
            <h6 className="text-[12px] lg:text-[16px]">ساعت</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center bg-[#2A3056] lg:w-[68px] lg:h-[68px] w-[52px] h-[52px] p-2 rounded-[16px]">
            <h6 className="m-0 lg:text-[20px] text-[14px]">{days < 10 ? "0" + days : days}</h6>
            <h6 className="text-[12px] lg:text-[16px]">روز</h6>
          </span>
        </div>
      );
    }
  };

  return (
    <div
      style={{ backgroundImage: "url(Frame.png)" }}
      className="mt-60 bg-center "
    >
      <div class="">
        <div class="container">
          {loading ? (
            <Loading />
          ) : (
            <Reveal head>
              <div class="row justify-center">
                <div
                  style={{
                    boxShadow: "0px 3px 12px 0px rgba(0, 0, 0, 0.08)",
                    backdropFilter: "blur(16px)",
                  }}
                  class="bg-white relative -top-14 pt-24 lg:pt-32 px-4 pb-4 lg:px-10 lg:pb-10 rounded-[16px]"
                >
                  <div class="  flex flex-col items-center justify-center">
                    <span
                      style={{
                        backdropFilter: "blur(20px)",
                        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.12)",
                      }}
                      className="bg-white p-8 lg:p-12 rounded-full absolute top-0 -translate-y-1/2"
                    >
                      <img
                        src="/gift.png"
                        className="w-full max-w-[100px] lg:max-w-[160px]"
                        alt="Gift Sirir"
                      />
                    </span>
                    <div className="flex justify-center my-3">
                      {hour && (
                        <Countdown date={new Date(hour)} renderer={renderer} />
                      )}
                    </div>
                    <div class="text-[32px] lg:text-[48px] text-[#1B1F37]">
                      <span class=" ">هدیه ویژه منتظرته!</span>
                    </div>

                    {completed ? (
                      <></>
                    ) : (
                      <>
                        <div class="text-[16px] lg:text-[24px] text-[#1B1F37] font-[Shabnam-thin]">
                          <span>دوره رایگان {data?.name}</span>
                        </div>
                        {step ? (
                          <div className="flex relative font-[Shabnam-thin] w-full mt-10">
                            <input
                              className="w-full h-[52px] px-12 placeholder:font-thin font-thin rounded-full outline-none bg-[#F5F5F5] placeholder:text-[#A5A5A5] text-[#434343]"
                              placeholder="کد تایید ارسالی را وارد کنید"
                              type="text"
                              id="code"
                              name="code"
                              value={code}
                              onChange={(e) => setcode(e.target.value)}
                            />
                            <FaLock
                              color="#434343"
                              className="absolute right-6 top-1/2 -translate-y-1/2"
                            />
                            <button
                              onClick={acceptUser}
                              className=" btn--e-brand absolute left-0 top-1/2 -translate-y-1/2 h-full px-5"
                            >
                              ثبت
                            </button>
                          </div>
                        ) : (
                          <div className="flex relative font-[Shabnam-thin] w-full mt-10">
                            <input
                              className="w-full h-[52px] px-12 placeholder:font-thin font-thin rounded-full outline-none bg-[#F5F5F5] placeholder:text-[#A5A5A5] text-[#434343]"
                              placeholder="شماره موبایل"
                              type="text"
                              id="phone"
                              name="phone"
                              value={phone}
                              onChange={(e) => setphone(e.target.value)}
                            />
                            <FaRegUser
                              color="#434343"
                              className="absolute right-6 top-1/2 -translate-y-1/2"
                            />
                            <button
                              onClick={postUser}
                              disabled={completed}
                              className=" btn--e-brand absolute left-0 top-1/2 -translate-y-1/2 h-full px-5"
                            >
                              ثبت
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Reveal>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dontmiss;
