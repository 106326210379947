import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Reveal from "../motion/Reveal";
import Accordion from "../common/Accordion";

const FAQ = () => {
  return (
    <div class="u-s-p-y-80 ">
      <div className="section__intro u-s-m-b-30">
        <div className="container">
          <div>
            <Reveal head>
              <div className="w-full flex justify-between text-[#292929] px-5">
                <h2 className="text-[24px] lg:text-[40px] ">سوالات متداول</h2>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
      <div className="section__content left-right-section py-6">
        <div className="container">
          <div className="row gap-4">
            <div className="col-lg-12">
              <Accordion
                question={"لورم ایپسوم چیست؟"}
                value={
                  "لورم ای ن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون  بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد."
                }
              />
            </div>
            <div className="col-lg-12">
              <Accordion
                question={"لورم ایپسوم چیست؟"}
                value={
                  "لورم ای ن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون  بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد."
                }
              />
            </div>
            <div className="col-lg-12">
              <Accordion
                question={"لورم ایپسوم چیست؟"}
                value={
                  "لورم ای ن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون  بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد."
                }
              />
            </div>
            <div className="col-lg-12">
              <Accordion
                question={"لورم ایپسوم چیست؟"}
                value={
                  "لورم ای ن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون  بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد."
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
