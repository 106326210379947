import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../motion/Reveal";
import ProductCard from "../common/ProductCard";
import { httpReauest } from "../../utils/httprequest";
import { FaAngleLeft } from "react-icons/fa";

const TopProducts = () => {
  const [data, setData] = useState();
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const newData = await httpReauest("GET", "/product/top", {}, {});

    setData(newData.data.data);
  }
  return (
    <div className="u-s-p-b-60 u-s-p-t-60">
      <div className="section__intro  ">
        <div className="container ">
          <div>
            <Reveal head>
              <div className="w-full flex justify-between text-[#292929]  left-right-section-2 pb-6">
                <h2 className="text-[32px] lg:text-[40px] ">ترند ها</h2>
                <Link to={"/products"} className="flex items-center gap-1 text-[14px]">
                  مشاهده همه
                  <FaAngleLeft size={18}/>
                </Link>
              </div>
            </Reveal>
          </div>
        </div>
      </div>

      <div className="section__content ">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <div className="filter__grid-wrapper u-s-m-y-10">
                <div className="flex overflow-x-scroll lg:overflow-hidden">
                  {data?.map((e) => {
                    return <ProductCard data={e} home />;
                  })}
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopProducts;
