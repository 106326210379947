import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";

const Accordion = ({ question, value }) => {
  const [show, setShow] = useState(false);
  return (
    <div class="bg-[#F9FAFA] rounded-[8px] px-7 py-5 ">
      <button
        onClick={() => setShow(!show)}
        class="w-full flex justify-between items-center  text-[#292929] text-[16px] lg:text-[26px]"
      >
        <span>{question}</span>
        <span
          id="icon-1"
          class={`${show ? "rotate-180" : ""}  transition-transform duration-300`}
        >
          <FaAngleDown />
        </span>
      </button>
      <div
        id="content-1"
        class={`${
          show ? "max-h-96" : "max-h-0"
        } overflow-hidden transition-all duration-300 ease-in-out font-[Shabnam-thin]`}
      >
        <div class="text-[14px] lg:text-[24px] text-[#292929] pt-6">{value}</div>
      </div>
    </div>
  );
};

export default Accordion;
