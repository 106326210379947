import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../motion/Reveal";
import { SrviceInfo } from "../../pages/ServiceDetails";
import { FaAngleLeft } from "react-icons/fa6";

const Header = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  return (
    <header class="header--style-1 relative">
      <img
        src="/Rectangle (2).png"
        className="absolute left-0 top-0 w-full h-full object-cover opacity-35"
        alt="bg pattern"
      />
      <Reveal head>
        <nav class="primary-nav ">
          <div class="container">
            <div class="primary-nav">
              <div className="lg:flex gap-3 items-center hidden">
                <Link className="bg-[#03C976] rounded-full text-white px-3 py-2">
                  شروع کن
                </Link>
                <Link className="text-white px-3 py-2">ورود</Link>
              </div>
              <div class="ah-lg-mode">
                <span class="ah-close">✕ Close</span>
                <ul class="ah-list ah-list--design2 ah-list--link-color-secondary">
                  <li class="has-dropdown">
                    <a>
                      خدمات<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                    </a>

                    <span class="js-menu-toggle"></span>
                    <ul style={{ width: "220px" }}>
                      {SrviceInfo.map((e) => {
                        return (
                          <li>
                            <Link to={"/service/" + e.id}>{e.title}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>

                  <li class="has-dropdown">
                    <a>
                      روادید<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                    </a>

                    <span class="js-menu-toggle"></span>
                    <ul style={{ width: "200px" }}>
                      <li>
                        <Link to="/event">سمینار</Link>
                      </li>
                      <li>
                        <Link to="/event">تور</Link>
                      </li>
                      <li>
                        <Link to="/event">کارگاه آموزشی</Link>
                      </li>
                      <li>
                        <Link to="/event">بوت کمپ</Link>
                      </li>
                    </ul>
                  </li>
                  <li class="has-dropdown">
                    <Link to="/products">
                      محصولات<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                    </Link>

                    <span class="js-menu-toggle"></span>
                    <ul style={{ width: "200px" }}>
                      <li>
                        <Link to="/products/courses">دوره های آموزشی</Link>
                      </li>
                      <li>
                        <Link to="/products/pdf-courses">
                          دوره های آموزشی PDF
                        </Link>
                      </li>
                      <li>
                        <Link to="/products/audio">فایل های صوتی</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/about">درباره سیریر</Link>
                  </li>
                  <li>
                    <Link to="/service/branding">ارتباط با ما</Link>
                  </li>
                  <li>
                    <Link to="/ُupport">پشتیبانی</Link>
                  </li>
                </ul>
              </div>

              <div class="menu-init" id="navigation">
                <button
                  class="btn btn--icon text-white toggle-button toggle-button--secondary fa-solid fa-align-justify"
                  type="button"
                  onClick={() => setShow(!show)}
                ></button>
                <Link className="bg-[#03C976] rounded-full text-white px-3 py-2 ms-5 lg:hidden">
                  شروع کن
                </Link>
                {show && (
                  <div
                    onClick={() => setShow(false)}
                    className="w-full h-full fixed top-0 left-0 z-40"
                  ></div>
                )}
                <div
                  style={{ borderRadius: "0px 0px 0px 40px" }}
                  className={`${
                    !show && "right-[-100%]"
                  } fixed right-0  bg-[#1a1a1a] z-50 duration-500 top-[61px]`}
                >
                  <div className="w-full">
                    <ul class="ah-list ah-list--design2 ah-list--link-color-secondary w-full">
                      <li>
                        <Link to="/">خانه</Link>
                      </li>
                      <li>
                        <Link to="/signup">ثبت نام</Link>
                      </li>
                      <li>
                        <Link to="/signin">ورود</Link>
                      </li>
                      <li class="has-dropdown">
                        <a>
                          خدمات<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                        </a>
                        <span
                          onClick={() => setShow2(!show2)}
                          class={`${
                            show2 ? "rotate-[-90deg]" : "rotate-0"
                          } absolute left-4 text-white top-[22px] -translate-y-1/2 duration-200`}
                        >
                          <FaAngleLeft />
                        </span>
                        {show2 && (
                          <ul style={{ width: "200px" }}>
                            {SrviceInfo.map((e) => {
                              return (
                                <li>
                                  <Link to={"/service/" + e.id}>{e.title}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>

                      <li class="has-dropdown">
                        <a>
                          روادید<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                        </a>

                        <span
                          onClick={() => setShow3(!show3)}
                          class={`${
                            show3 ? "rotate-[-90deg]" : "rotate-0"
                          } absolute left-4 text-white top-[22px] -translate-y-1/2 duration-200`}
                        >
                          <FaAngleLeft />
                        </span>
                        {show3 && (
                          <ul style={{ width: "200px" }}>
                            <li>
                              <Link to="/event">سمینار</Link>
                            </li>
                            <li>
                              <Link to="/event">تور</Link>
                            </li>
                            <li>
                              <Link to="/event">کارگاه آموزشی</Link>
                            </li>
                            <li>
                              <Link to="/event">بوت کمپ</Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li class="has-dropdown">
                        <a>
                          محصولات
                          <i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                        </a>
                        <span
                          onClick={() => setShow4(!show4)}
                          class={`${
                            show4 ? "rotate-[-90deg]" : "rotate-0"
                          } absolute left-4 text-white top-[22px] -translate-y-1/2 duration-200`}
                        >
                          <FaAngleLeft />
                        </span>
                        {show4 && (
                          <ul style={{ width: "200px" }}>
                            <li>
                              <Link to="/products/courses">
                                دوره های آموزشی
                              </Link>
                            </li>
                            <li>
                              <Link to="/products/pdf-courses">
                                دوره های آموزشی PDF
                              </Link>
                            </li>
                            <li>
                              <Link to="/products/audio">فایل های صوتی</Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li>
                        <Link to="/service/branding">درباره سیریر</Link>
                      </li>
                      <li>
                        <Link to="/service/branding">ارتباط با ما</Link>
                      </li>
                      <li>
                        <Link to="/suppourt">پشتیبانی</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="ah-lg-mode">
                  <span class="ah-close">✕ Close</span>

                  <ul class="ah-list ah-list--design1 ah-list--link-color-secondary flex items-center gap-2">
                    <li
                      class="has-dropdown"
                      data-tooltip="tooltip"
                      data-placement="left"
                      title="Account"
                    >
                      <span class="js-menu-toggle"></span>
                    </li>

                    <Link class="main-logo  mb-3" to="/">
                      <img className="w-[70px]" src="/logo.png" alt="" />
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="flex gap-4 items-center lg:hidden ">
                <Link class="py-3 mb-2" to="/">
                  <img className="w-[70px] " src="/logo.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </Reveal>
    </header>
  );
};

export default Header;
